import React, { CSSProperties, ReactElement, useState } from "react";

interface ImageWithDefaultProps {
  defaultImg: ReactElement;
  imgSrc?: string;
  altText?: string;
  styles?: CSSProperties;
  className?: string;
}

const ImageWithDefault = ({ defaultImg, imgSrc, altText, styles, className }: ImageWithDefaultProps) => {
  const [userImageHadError, setUserImageHadError] = useState(false);

  if (userImageHadError || !imgSrc) {
    return defaultImg;
  }

  return (
    <img src={imgSrc} style={styles} className={className} alt={altText} onError={() => setUserImageHadError(true)} />
  );
};

export default ImageWithDefault;
