import React, { CSSProperties } from "react";
import BasicProviderImage from "../providerimage/BasicProviderImage";
import "./basicSocialImage.css";
import ImageWithDefault from "../image/ImageWithDefault";

// TODO - resolve with SocialImage. Sort of duplicated and then diverging implementations

const generateStyles = (containerSize: number, providerImageSize: number) => {
  return {
    container: {
      position: "relative",
      height: `${containerSize}px`,
      width: `${containerSize}px`,
    },
    userImageContainer: {
      width: `${containerSize}px`,
      height: `${containerSize}px`,
      borderRadius: "50%",
      overflow: "hidden",
      border: "1px solid #eee",
    },
    userImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    defaultUserImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      color: "#b3b5ba",
      background: "#EEE",
      fontSize: `${Math.round(containerSize * (2 / 3))}px`,
      textAlign: "center",
      lineHeight: `${containerSize}px`,
    },
    providerImage: {
      width: `${providerImageSize}px`,
      height: `${providerImageSize}px`,
      borderRadius: "50%",
      position: "absolute",
      top: `${containerSize - providerImageSize}px`,
      left: `${containerSize - providerImageSize}px`,
      border: "solid 2px white",
    },
  };
};

export interface CustomProviders {
  [key: string]: {
    id: string;
    name: string;
    imageUrl?: string;
  };
}

interface BasicSocialImageProps {
  userImage: string;
  username?: string;
  provider: string;
  customProviders?: CustomProviders;
  setProviderImageForReviewerImage?: boolean;
  size?: number;
  providerImageSize?: number;
  className?: string;
  style?: CSSProperties;
}

const BasicSocialImage = ({
  userImage,
  username,
  provider,
  customProviders,
  setProviderImageForReviewerImage, // public
  size = 60, // pixel dimensions
  providerImageSize,
  className,
  style,
  ...props
}: BasicSocialImageProps): JSX.Element => {
  const styles = generateStyles(size, providerImageSize || Math.round(size * 0.4));

  const renderDefaultUserImage = () => {
    return !setProviderImageForReviewerImage ? (
      <div className="fas fa-user" style={styles.defaultUserImage as CSSProperties} />
    ) : (
      <BasicProviderImage
        provider={provider}
        customProviders={customProviders}
        style={styles.defaultUserImage as CSSProperties}
      />
    );
  };

  return (
    <div
      className={`cm-social-image-container ${className ? className : ""}`}
      style={
        {
          ...style,
          ...styles.container,
        } as CSSProperties
      }
      {...props}
    >
      <div style={styles.userImageContainer as CSSProperties}>
        <ImageWithDefault
          imgSrc={userImage}
          styles={styles.userImage as CSSProperties}
          defaultImg={renderDefaultUserImage()}
        />
      </div>
      {provider ? (
        <BasicProviderImage
          provider={provider}
          customProviders={customProviders}
          style={styles.providerImage as CSSProperties}
        />
      ) : undefined}
    </div>
  );
};

export default BasicSocialImage;
